import React, { useEffect, useState } from 'react';
import SVG from 'react-inlinesvg';
import Emoji from 'a11y-react-emoji';
import Helmet from 'react-helmet';

import Title from './Title';
import Page from '../../components/Page';
import Button from '../../components/Button';
import * as Yup from "yup";

import './index.scss';
import { Formik } from 'formik';


const contactFormSchema = Yup.object().shape({
  name: Yup.string()
    .required("❌ Ce champ est requis."),
  email: Yup.string()
  .email()
  .required("❌ Ce champ est requis et doit contenir une adresse email valide."),
  subject: Yup.string()
  .required("❌ Ce champ est requis."),
  message: Yup.string()
    .required("❌ Ce champ est requis.")
})
export default function Contact(props) {
    const [data, setData] = useState({});
    const [successSubmitting, setSuccessSubmitting] = useState(false);
    const [errorSubmitting, setErrorSubmitting] = useState(false);
    const [submitLabel, setSubmitLabel] = useState('Envoyer');
    function submitContactForm(values, { setSubmitting }) {
      setSubmitting(true);
      setSubmitLabel('Envoi en cours...');
      const formData = new FormData();
      formData.append('action','sendMail');
      formData.append('payload', JSON.stringify(values));
      const url = process.env.NODE_ENV !== 'production' ? 'https://ew-2019.ew-staging.com/tools.php': 'tools.php';
      fetch(url, {
        "method": 'post',
        "body": formData,
      }).then((response) => {       
        return response.json();
      }).then(json => {
        setSubmitting(false);
        if(json.success) {
          setSubmitLabel('Message bien envoyé, merci !');
          setSuccessSubmitting(true);
        } else {
          setSubmitLabel('Message bien envoyé, merci !');
          setErrorSubmitting('Erreur en envoyant le message, réessayez plus tard :/')
        }
        
      });
    }
    useEffect(() => {
      setData(props.data);
    }, [props.data])
    
    return (
      <Page { ...props } className={'page-container--row'}>
        <Helmet>
          <title>Contact et demandes de devis : Enguerran Weiss, développeur web indépendant</title>
          <meta name="description" content="Utilisez ce formulaire pour me contacter : demande de devis pour un site web, une application mobile ou simplement une question :)" />
          <meta property="og:type" content="website" />
          <meta property="og:url" content="https://metatags.io/" />
          <meta property="og:title" content="Contact et demandes de devis : Enguerran Weiss, développeur web indépendant"/>
          <meta property="og:description" content="Utilisez ce formulaire pour me contacter : demande de devis pour un site web, une application mobile ou simplement une question :)"/>
          <meta property="og:image" content=""/>
          <meta property="twitter:card" content="summary_large_image"/>
          <meta property="twitter:url" content="https://metatags.io/"/>
          <meta property="twitter:title" content="Contact et demandes de devis : Enguerran Weiss, développeur web indépendant"/>
          <meta property="twitter:description" content="Utilisez ce formulaire pour me contacter : demande de devis pour un site web, une application mobile ou simplement une question :)"/>
          <meta property="twitter:image" content=""/>
        </Helmet>
        <div className="page-container__title page-container__title--contact">
          <Title color={props.color}/>
        </div>
        <div className="page-container__content transition-aside-element">

        <Formik
            initialValues={{ 
              name:"",
              email: "",
              subject: "",
              message: ""
            }}
            validateOnChange={false}
            validateOnBlur={false}
            onSubmit={submitContactForm}
            validationSchema={contactFormSchema}
          >
            {props => {
              const {
                values,
                errors,
                isSubmitting,
                handleChange,
                handleSubmit,
              } = props;
              return (
                <form onSubmit={handleSubmit}>
                  <p className="contact-form__intro wysiwyg-content">
                    Si vous avez un <strong>projet</strong>, une <strong>question</strong> <Emoji symbol="🤔" label="interrogation" />, ou simplement entrer en contact avec moi, n'hésitez pas à utiliser ce formulaire.
                  </p>
                  <p className="contact-form__availability">
                    <Emoji symbol="📆" label="calendrier" /> Prochaine dispo pour un projet : <strong>{data.availability || 'Nov. 2020'}</strong>
                  </p>
                  <div className="input-wrapper">
                    <input 
                      value={values.name}
                      onChange={handleChange}
                      type="text"
                      id="contact-form-name"
                      className={`input ${values.name !== '' ? 'input--filled':''}`}
                      name="name" 
                      aria-required="true"
                      aria-labelledby="label-contact-form-name"
                      />
                      <span className="label-container">
                        <label id="label-contact-form-name" htmlFor="contact-form-name">Votre nom *</label>
                        <span className="label-jp" lang="ja">お名前</span>
                      </span>
                    
                    {
                      errors.name &&
                      <span className="form-error">{errors.name}</span>
                    }
                  </div>
                  <div className="input-wrapper">
                    <input 
                      value={values.email}
                      onChange={handleChange}
                      type="text"
                      id="contact-form-email"
                      className={`input ${values.email !== '' ? 'input--filled':''}`}
                      name="email"
                      aria-required="true"
                      aria-labelledby="label-contact-form-email"
                      />
                      <span className="label-container">
                        <label id="label-contact-form-email" htmlFor="contact-form-email">Votre adresse email *</label>
                        <span className="label-jp" lang="ja">メールアドレス</span>
                      </span>
                    
                    {
                      errors.email &&
                      <span className="form-error">{errors.email}</span>
                    }
                  </div>
                  <div className="input-wrapper">
                    <input 
                      value={values.subject}
                      onChange={handleChange}
                      type="text"
                      id="contact-form-subject"
                      aria-required="true"
                      className={`input ${values.subject !== '' ? 'input--filled':''}`}
                      name="subject" />
                      <span className="label-container">
                        <label htmlFor="contact-form-subject">Pourquoi me contactez-vous ? *</label>
                        <span className="label-jp" lang="ja">ご用件</span>
                      </span>
                    
                    {
                      errors.subject &&
                      <span className="form-error">{errors.subject}</span>
                    }
                  </div>
                  <div className="input-wrapper">
                    <textarea 
                      value={values.message}
                      onChange={handleChange}
                      type="text"
                      rows={1}
                      id="contact-form-message"
                      className={`input ${values.message !== '' ? 'input--filled':''}`}
                      name="message"
                      aria-labelledby="label-contact-form-message"
                      aria-required="true"
                      ></textarea>
                      <span className="label-container">
                        <label id="label-contact-form-message" htmlFor="contact-form-message">Votre message *</label>
                        <span className="label-jp" lang="ja">メッセージ</span>
                      </span>
                    
                    {
                      errors.name &&
                      <span className="form-error">{errors.message}</span>
                    }
                  </div>
                
                  <div className="input-wrapper">
                    <Button disabled={isSubmitting || successSubmitting || errorSubmitting} className={`button--full ${isSubmitting ? 'button--loading':''} ${successSubmitting ? 'button--success':''} ${errorSubmitting ? 'button--error':''}`} type="submit" icon={<SVG src="/images/icons/envelope.svg" />} label={submitLabel} />

                  </div>
                </form>
              );
            }}
          </Formik>
            
        </div>  
        
      </Page>      
    )
}