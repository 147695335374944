import React, {useContext, useEffect} from 'react';
import { NavLink } from "react-router-dom";

import './index.scss';
import { DocumentContext } from '../../contexts/DocumentContext';

function Menu () {
  const {documentData, setDocumentData} = useContext(DocumentContext);
  function onToggleClick() {
    setDocumentData({
      ...documentData,
      menuOpened: !documentData.menuOpened,
    })
    if(documentData.menuOpened) {
      window.history.pushState("", document.title, window.location.pathname + window.location.search);
    }
  }
  useEffect(() => {
    if (("onhashchange" in window)) {
      window.onhashchange = function () {
        setDocumentData({
          ...documentData,
          menuOpened: window.location.hash === '#main-menu',
        })
      }
    }
  }, );
  return (
    <React.Fragment>
      <div className={`main-menu ${documentData.menuOpened ? 'main-menu--opened':''}`} >
        <button className="main-menu__toggle" aria-expanded={documentData.menuOpened} onClick={() => onToggleClick()}>
            <div className="main-menu__toggle-wrapper">
              <div className="main-menu__toggle-bar main-menu__toggle-bar--top"></div>
              <div className="main-menu__toggle-bar main-menu__toggle-bar--middle"></div>
              <div className="main-menu__toggle-bar main-menu__toggle-bar--bottom"></div>
            </div>
          <span className="main-menu__toggle-label">Menu</span>
          <span className="main-menu__toggle-label label-jp" lang="ja">メニュー</span>
        </button>
      
      
      </div>
      <nav id="main-menu" className={`main-menu__inner ${documentData.animated ? 'main-menu__inner--no-transition':''} ${documentData.menuOpened ? 'main-menu__inner--opened':''}`} role="navigation" aria-label="Menu principal">
      <ul aria-label="Menu principal" role="menu" style={{margin: documentData.width / documentData.columns * 4.7}}>
        <li className={'transition-aside-element'}>
          <NavLink role="menuitem" exact tabIndex="0" to="/" onClick={() => onToggleClick()} >Accueil <span className="label-jp main-menu__alt-label" lang="ja">ホーム</span></NavLink>
        </li>
        <li className={'transition-aside-element'}>
          <NavLink role="menuitem" tabIndex="0" to="/a-propos" onClick={() => onToggleClick()}>À propos <span className="label-jp main-menu__alt-label" lang="ja">じこしょうかい</span></NavLink>
        </li>
        <li className={'transition-aside-element'}>
          <NavLink role="menuitem" tabIndex="0" to="/projets-developpement-web-mobile" onClick={() => onToggleClick()} >Références <span className="label-jp main-menu__alt-label" lang="ja">ポートフォリオ</span></NavLink>
        </li>
        <li className={'transition-aside-element'}>
          <NavLink role="menuitem" tabIndex="0" to="/contact" onClick={() => onToggleClick()}>Contact <span className="label-jp main-menu__alt-label" lang="ja">メッセージ</span></NavLink>
        </li>
      </ul>
    </nav>

    </React.Fragment>
      
    )

}

export default Menu;