import React, {useState, useEffect} from 'react';
import Helmet from 'react-helmet';

import Page from '../../components/Page';
import ProjectThumbnail from '../../components/ProjectThumbnail';
import SVG from 'react-inlinesvg';

import './index.scss';

function Projects(props) {
  const [tag, setTag] = useState(null);
  const defaultText = 'Filtrer par...';
  useEffect(() => {

  }, [])
  function getFilteredProjects() {
    let projects = props.data;
    if(tag && tag !== defaultText) {
      projects = projects.filter(project => {
        const allTags = project.data.languages.map(language => language.language);
        return allTags.indexOf(tag) > -1;
      })
    }
    return projects;
  }
  function filterProjects(tag){
    setTag(tag);
  }
  function renderTags() {
    const allTags = props.data.map((project) => project.data.languages).flat();
    const tags = [...new Set(allTags.map(tag => tag.language))];
    return tags.map((tag) => <option key={tag} value={tag} >{tag}</option>
      )
  }
  function renderProjects() {
    return getFilteredProjects().map((project) => <ProjectThumbnail key={project.uid} href={`/projets-developpement-web-mobile/${project.uid}`} mouseX={props.mouseX} mouseY={props.mouseY} project={project} onMouseEnter={props.onMouseEnter} onMouseLeave={props.onMouseLeave} />);
  }
    return (
      <Page { ...props }>
        <Helmet>
          <title>Mes références : Enguerran Weiss, développeur web indépendant</title>
          <meta name="description" content="Présentation de mes références, une sélection de sites web et applications mobiles choisies - HTML, CSS, JS, React, PHP, NodeJS, React Native, Wordpress, Symfony" />
          <meta property="og:type" content="website" />
          <meta property="og:url" content="https://metatags.io/" />
          <meta property="og:title" content="Mes références : Enguerran Weiss, développeur web indépendant"/>
          <meta property="og:description" content="Présentation de mes références, une sélection de sites web et applications mobiles choisies - HTML, CSS, JS, React, PHP, NodeJS, React Native, Wordpress, Symfony"/>
          <meta property="og:image" content=""/>
          <meta property="twitter:card" content="summary_large_image"/>
          <meta property="twitter:url" content="https://metatags.io/"/>
          <meta property="twitter:title" content="Mes références : Enguerran Weiss, développeur web indépendant"/>
          <meta property="twitter:description" content="Présentation de mes références, une sélection de sites web et applications mobiles choisies - HTML, CSS, JS, React, PHP, NodeJS, React Native, Wordpress, Symfony"/>
          <meta property="twitter:image" content=""/>
        </Helmet>
        <div className="page-container__title">
        
          <h2><span className="transition-inline-element">mes</span><br /><strong className="transition-inline-element">références</strong></h2>
          <small className="label-jp transition-inline-element--jp" lang="ja">ポートフォリオ</small> 
          {/* <span className="projects__filters-header">Filtre : </span> */}
          <div className="projects__filters-wrapper transition-inline-element--jp">
            <span className="projects__filters-value">{tag || defaultText}</span>
            <SVG className="projects__filters-dropdown" src="/images/icons/dropdown.svg" />
            <select onChange={(event) => filterProjects(event.target.value)}>
            <option value={null} >{defaultText}</option>
            {renderTags()}
            </select>
          </div>
          
        </div>
        <div className='projects__inner transition-aside-element'>
          {
            renderProjects()
          }
        </div>
      </Page>
      
    )


}
export default Projects;