import React, {useState} from 'react';
import './index.scss';

function AvoidMenu(props) {  
  const [opened, setOpened] = useState(false);
  function onLinkFocus() {
    setOpened(true);
  }
  function onLinkBlur() {
    setOpened(false);
  }
  return (
    <nav className={`avoid-menu ${opened ? '':'avoid-menu--hidden'}`} role="navigation" aria-label="Menu d'évitement">
      <ul className="avoid-menu__wrapper" aria-label="Menu d'évitement">
        <li className="avoid-menu__item"><a role="menuitem" href="#main-menu" tabindex="0" onBlur={onLinkBlur} onFocus={onLinkFocus}>Accéder au menu principal</a></li>
        <li className="avoid-menu__item"><a role="menuitem" href="#main-content" tabindex="0" onBlur={onLinkBlur} onFocus={onLinkFocus}>Accéder directement au contenu</a></li>
        <li className="avoid-menu__item"><a role="menuitem" href="#main-footer" tabindex="0" onBlur={onLinkBlur} onFocus={onLinkFocus}>Accéder aux infos légales</a></li>
      </ul>
    </nav>
  )
    
}

export default AvoidMenu;