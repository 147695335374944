import React, { createContext, useState, useEffect } from 'react';

export const DocumentContext = createContext();


export default function DocumentContextProvider(props) {
  function getColumns() {
    return window.innerWidth < 1000 ? 24 : 48;
  }
  function getGridColumns() {
    return 48;
  }
  function getDocumentWidth() {
    return Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
  }
  function getDocumentHeight() {
    return Math.max(document.documentElement.clientHeight, window.innerHeight || 0);
  }
  const [documentData, setDocumentData] = useState({
    columns: getColumns(),
    width: getDocumentWidth(),
    height: getDocumentHeight(),
    gridColumns: getGridColumns(),
    menuOpened: false,
    animated: true,
  });
  useEffect(() => {
    if(documentData.animated) {
      document.querySelectorAll('body')[0].classList.remove('no-animation');
    } else {
      document.querySelectorAll('body')[0].classList.add('no-animation');
    }
  }, [documentData.animated])
  return (
    <DocumentContext.Provider value={{documentData, setDocumentData}}>
      {props.children}
    </DocumentContext.Provider>
  )
}