import 'react-app-polyfill/ie11';
import 'core-js/features/array/flat';

import React, { useState, useEffect, useRef } from 'react';
import Prismic from 'prismic-javascript';
import FontFaceObserver from 'fontfaceobserver';

import PrismicEndpoints from './config/Prismic';

import DocumentContextProvider from './contexts/DocumentContext';
import Layout from './components/Layout';


function App() {
  const [projects, setProjects] = useState([]);
  const [loading, setLoading] = useState(true);
  const [fontsLoading, setFontsLoading] = useState(true);
  const [contentLoading, setContentLoading] = useState(true);
  const [imagesLoading, setImagesLoading] = useState(true);
  const loadingStarted = useRef();
  const loadingEnded = useRef();
  useEffect(() => {
    if(!loadingStarted.current) {
      loadingStarted.current = true;
      getPrismicContent();
      getFonts(); 
    }       
    if(!contentLoading && !fontsLoading && !imagesLoading) {
      loadingEnded.current = true;
      onLoadingEnd();
    }
  },[contentLoading, fontsLoading, imagesLoading]);
  function onLoadEnded() {
   // console.info('EW Portfolio: ready to display')
    document.querySelectorAll('body')[0].classList.remove('loading');
  }
  
  function getFonts() {
    const monostenRegular = new FontFaceObserver('Monosten');
    const monostenBold = new FontFaceObserver('Monosten', {
      weight: 'bold'
    });
    const oswaldRegular = new FontFaceObserver('Oswald');
    const oswaldBold = new FontFaceObserver('Oswald', {
      weight: 'bold'
    });
    const proximaNovaRegular = new FontFaceObserver('Proxima Nova');
    const proximaNovaBold = new FontFaceObserver('Proxima Nova', {
      weight: 'bold',
    });
    const proximaNovaItalic = new FontFaceObserver('Proxima Nova', {
      style: 'italic',
    });
    
    Promise.all([
      monostenRegular.load(), 
      monostenBold.load(), 
      proximaNovaRegular.load(), 
      proximaNovaBold.load(), 
      proximaNovaItalic.load(),
      oswaldRegular.load(),
      oswaldBold.load(),
    ]).then(() => {
      setFontsLoading(false);
      onLoadEnded();      
    });
  }
  function getPrismicContent(query = '') {
    Prismic.getApi(PrismicEndpoints().root).then((api) => {
	
      return api.query(

        [ Prismic.Predicates.at('document.type', 'projects') ], 
	
        { pageSize : 25, page : 1, orderings : '[my.projects.date desc]'});
      
      }).then((response) => {
        setContentLoading(false);
        setProjects(response.results);
        getImages(response.results);
        
    }, (err) => {
      
      console.log("Something went wrong: ", err);
      
    });
  }
  function onImagesLoaded() {
    onLoadEnded();
  }
  function getImages(projects) {  
    const images = projects.map(project => project.data.slider.map(slide => slide.slider_img.url)).flat();
    let remaining = images.length;
    let img;
    images.forEach(image => {
      img = new Image();
        img.onload = () => {
          --remaining;
          if (remaining <= 0) {
            setImagesLoading(false);
            onImagesLoaded();
          }
        };
        img.src = image;
    });
  }
  function onLoadingEnd() {
    if(!fontsLoading && !contentLoading) {
      setLoading(false);
      document.getElementById('loading').outerHTML = '';
    }
  }
  let jsx = (
    <React.Fragment />
  );
  if(!loading && !contentLoading && !fontsLoading){
    jsx = (
      <DocumentContextProvider>
        <Layout projects={projects}/>
      </DocumentContextProvider>     
       
    );
  }

  return jsx;
}
export default App;
