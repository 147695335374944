import React from 'react';
import { Link } from "react-router-dom";
import './index.scss';

function Button(props) {   
  let jsx;
  const base = (
    <span tabIndex="0" className={`button mouse-hover ${props.small ? 'button--small':''} ${props.round ? 'button--round':''} ${props.vertical ? 'button--vertical':''} ${props.className ? props.className:''}`} title={props.title}>
      <span className="button__icon">{ props.icon }</span> 
      { props.label && 
        <span className="button__label">{props.label}</span>
      }      
      { props.altLabel && 
        <span className="button__label-alt-wrapper">
          <span className="button__label-alt label-jp" lang="ja">{props.altLabel}</span>
        </span>
        
      }  
    </span>
  );
  if(props.href && !props.out && !props.download){
    jsx = (
      <Link to={props.href} title={props.label} aria-label={props.ariaLabel}>
        {base}
      </Link>
    )
  }
  else if (props.href && props.out){
    jsx = (
      <a tabIndex="0" href={props.href} aria-label={props.ariaLabel} title={props.label} target="_blank" rel="noopener noreferrer">
        {base}
      </a>
    )
  }
  else if (props.href && props.download) {
    jsx = (
      <a tabIndex="0"aria-label={props.ariaLabel}  href={props.href} title={props.label} download target="_self" rel="noopener noreferrer">
        {base}
      </a>
    )
  }
  else if (props.type === 'submit'){
    jsx = (<button className="button-wrapper" tabIndex="0" type="submit">
        {base}
    </button>)
  }
  else {
    jsx = base;
  } 
  return jsx;
    
}

export default Button;