import React, {useEffect} from 'react';
import './index.scss';

export default function Page(props) {
  useEffect(() => {
    document.querySelectorAll('.content-wrapper')[0].scrollTo(0,0);
    props.updateCurrentRoute(props.location.pathname);
  }, []);
  const margin = props.documentWidth / props.columns * 3.7;
  return (
    <div role="main" id="main-content" className={`page-container ${props.className}`} style={{marginLeft: margin, marginRight: margin}}>
      {props.children}
    </div>
  )
}
