import React from 'react';
import SVG from 'react-inlinesvg';
import Emoji from 'a11y-react-emoji';

import Title from './Title';
import Page from '../../components/Page';
import Button from '../../components/Button';
import cgv from '../../assets/media/CGV-WEISS-2020.pdf';
import rc from '../../assets/media/rc-pro-eweiss-2020.pdf';

import './index.scss';


export default function Legals(props) {
    
    return (
      <Page { ...props } className={'page-container--row'}>
        <div className="page-container__title">
          <Title color={props.color}/>
          <div className="buttons-container transition-aside-element">
            <Button onMouseLeave={props.onMouseLeave} onMouseEnter={props.onMouseEnter} download href={cgv} label="CGV" icon={<SVG src="/images/icons/download.svg" />} />
            <Button onMouseLeave={props.onMouseLeave} onMouseEnter={props.onMouseEnter} download href={rc} label="RC Pro" icon={<SVG src="/images/icons/download.svg" />}/>
          </div>        
        </div>
        <div className="transition-inline-element page-container__content wysiwyg-content">
          <h2>Éditeur du site et directeur de publication</h2>
          <ul>
            <li><Emoji symbol="👨‍💻" label="homme travaillant derrière un ordinateur" /> Enguerran Weiss</li>
            <li><Emoji symbol="📮" label="boîte à lettres" /> 55 avenue de la Libération, 86000 Poitiers</li>
            <li><Emoji symbol="📨" label="email" /> enguerranweiss@gmail.com</li>

          </ul>
          <p>L’ensemble de ce site relève des législations françaises et internationales sur le droit d’auteur et la propriété intellectuelle. Tous les droits de reproduction sont réservés.</p>
          <p>Crédits photos et vidéos (hors projets précisés) : Enguerran Weiss.</p>
          

          

          

          <h2>L’entreprise</h2>
          <ul>
            <li>Forme juridique : Entreprise Individuelle à Responsabilité Limitée (EIRL)</li>
            <li>N° SIRET : 788 869 972 00018</li>
            <li>N° TVA intracommunautaire : FR 48 788869972</li>
          </ul>
          

          
          <h2>Prestataire d’hébergement</h2>
          <ul>
            <li>O2SWITCH</li>
            <li>222-224 Boulevard Gustave Flaubert, 63000 Clermont-Ferrand</li>
            <li>SARL o2switch au capital de 7500€</li>
            <li> RCS : Clermont-Ferrand – SIRET : 510 909 80700016 – TVA : FR35510909807</li>
          </ul>
          
          <h2>Données personnelles</h2>
          <p>Conformément aux dispositions de la loi n° 78-17 du 6 janvier 1978 relative à l’informatique, aux fichiers et aux libertés, vous disposez d’un droit d’accès, de modification, de rectification et de suppression aux données qui vous concernent.</p>
        </div>  
        
      </Page>      
    )
}