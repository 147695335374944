import React, {useContext} from 'react';
import { DocumentContext } from '../../contexts/DocumentContext';
import './index.scss';
import { Link } from "react-router-dom";


function Footer(props) {   
  const margin = props.documentWidth / props.columns * 3.7; 
  const {documentData, setDocumentData} = useContext(DocumentContext);
  function onAnimStopClick() {
    setDocumentData({
      ...documentData,
      animated: !documentData.animated,
    })
  }
  return (
    <footer role="contentinfo" id="main-footer" className="main-footer" style={{left: margin, right: margin}}>
      <div className="main-footer__legals">© enguerran weiss - 2020 • <Link to="/mentions-legales">mentions légales</Link> - <span aria-hidden="true" className="main-footer__disable-animations" onClick={onAnimStopClick}>{documentData.animated ? 'Désactiver les animations':'Activer les animations'}</span> </div>
    </footer>
  )
}

export default Footer;