import React, { useContext, useEffect } from 'react';
import PrismicDOM from 'prismic-dom';
import SVG from 'react-inlinesvg';

import Button from '../../components/Button'; 
import Page from '../../components/Page';
import './index.scss';
import Previewer from '../../components/Previewer';
import { DocumentContext } from '../../contexts/DocumentContext';

function Project (props) {
  const {documentData} = useContext(DocumentContext);
  function getProjectYear(project) {
    return project.data.date.split('-')[0];
  }
  useEffect(() => {
  }, []);
  function renderLanguages(project) {
    return project.data.languages.map((element, index) => {

      let html = <span className="project-single__language" key={element.language}>{index ? ' / ':''}{element.language}</span>;
      return html;
    });
  }
  function renderPreviews() {
    return project.data.slider.slice(1).map((preview) => {
      const jsx = preview.slider_img.url ? <img src={preview.slider_img.url} alt="" key={preview.slider_img.url} crossOrigin="anonymous"/>:null;
      return jsx;
    });
  }
  const { projectUid } = props.match.params;
  const project = props.data.filter(project => project.uid === projectUid)[0];
  const projectsId = props.data.map(project => project.uid);
  const projectIndex = props.data.findIndex(project => project.uid === projectUid);
  return (
      <Page {...props}>
          <nav className={'project-single__navigation transition-aside-element'}>
            {
              projectIndex > 0 && 
              <Button className='button--left' href={`/projets-developpement-web-mobile/${projectsId[projectIndex-1]}`} title="Projet précédent" vertical round icon={<SVG src="/images/icons/arrow-left.svg" />} />
            }
            {/* <Button vertical className={'project-single__top-button'} round icon={<SVG src="/images/icons/arrow-top.svg" />} /> */}
            {
              (projectIndex < projectsId.length - 1) &&   
              <Button className='button--right' href={`/projets-developpement-web-mobile/${projectsId[projectIndex+1]}`} title="Projet suivant" vertical round icon={<SVG src="/images/icons/arrow-right.svg" />} />
            } 
          </nav>
          <header className="project-single__header transition-aside-element">
            
            <h2 dangerouslySetInnerHTML={{__html:project.data.title[0].text}}></h2>
            <h3><span className="project-single__date">{getProjectYear(project)}</span> - <span className="project-single__languages">{renderLanguages(project)}</span></h3>
            
          </header>
          <div className={`project-single__aside project-single__aside--${project.data.type}`} style={{'right': (documentData.documentWidth / documentData.columns * 4.7)+'px'}}>
            {
              documentData.animated &&
              <Previewer className="transition-aside-element " type={project.data.type} data={project.data.slider} mouseX={props.mouseX} mouseY={props.mouseY} documentWidth={documentData.height+200} documentHeight={documentData.width+200} />

            }
            <div className={`project-single__aside-buttons transition-aside-element`}>
              <Button out href={project.data.url.url} icon={<SVG src="/images/icons/hand-point.svg" />} label="voir le projet" altLabel='プロジェクトを見る' />
              <Button href="/projets-developpement-web-mobile" icon={<SVG src="/images/icons/cubes.svg" />} label="retour à la liste" altLabel='ポートフォリオを見る' />
              <Button href="/contact" icon={<SVG src="/images/icons/envelope.svg" />} label="Me contacter" altLabel='メッセージ' />
            </div>
            
          </div>
          <section className="transition-aside-element project-single__content project-single__content--resume" dangerouslySetInnerHTML={{ __html: PrismicDOM.RichText.asHtml(project.data.resume) }}></section>
          <aside className="transition-aside-element project-single__content project-single__main-gallery">
            {renderPreviews()}
          </aside>
          
          <section className="project-single__content transition-aside-element" dangerouslySetInnerHTML={{ __html: PrismicDOM.RichText.asHtml(project.data.content) }}></section>     
          {/* <div className="project-single__content project-single__buttons buttons-container  transition-aside-element">
            <Button href="/references" icon={<SVG src="/images/icons/cubes.svg" />} label="retour à la liste" />
            <Button out href={project.data.url.url} icon={<SVG src="/images/icons/dice-d10.svg" />} label="voir le projet" />
          </div> */}
          
            

  </Page>
  
  )
}
export default Project;