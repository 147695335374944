import React from 'react';
import './index.scss';


export default function Block(props) {
  const height = '28vh';
  const width = '100%'; 
  return (
    <div className={`block transition-aside-element ${props.className}`} tabIndex="2" style={{
      width: `${width}`, 
      height: `${height}`,      
    }}>
      {
        props.href &&
        <a target="_blank" href={props.href} rel="noopener noreferrer" title="Suivre le lien externe" className="block__overlay-link"></a>
      }
      {props.children}
    </div>
  )
}