import React from 'react';
import './index.scss';

class Grid extends React.PureComponent {
  COLS = this.props.columns;
  SPACING = Math.floor(this.props.documentWidth / this.COLS);
  ROWS = Math.ceil(this.props.documentHeight / this.SPACING) + 2;
  NUM_PARTICLES = ((this.ROWS) * (this.COLS));
  MARGIN = this.SPACING;
  THICKNESS = Math.pow(60, 2);  
  
  COLOR = 255;
  DRAG = 0.95;
  EASE = 0.25;
  container;
  particle = {
    vx: 0,
    vy: 0,
    x: 0,
    y: 0,
    r: 2
  };
  canvas;
  mouse;
  list;
  ctx;
  tog;
  man;
  dx;
  dy;
  mx; 
  my;
  d; 
  t; 
  f;
  a; 
  b;
  i; 
  n;
  w; 
  h;
  p; 
  s;
  r; 
  c;

  constructor(props) {
    super(props);
    this.initDots = this.initDots.bind(this);
    this.step = this.step.bind(this);
  }
  
  componentWillMount() {
    
    window.addEventListener('resize', this.initDots);
  }
  componentDidUpdate() {
    this.COLS = this.props.columns;
    this.ROWS = Math.ceil(this.props.documentHeight / this.SPACING) + 2;
    this.bounds = document.body.getBoundingClientRect();

    this.mx = this.props.mouseEnd ? 10000 : this.props.mouseX - this.bounds.left;
    this.my = this.props.mouseEnd ? 10000 : this.props.mouseY - this.bounds.top  - window.pageYOffset ;
    this.man = true;
    
  }
  componentDidMount() {
    this.canvas = this.refs.canvas;
    
    this.initDots();
    this.step();
  }
  componentWillUnmount() {
    
  }  
  initDots() {
    this.SPACING = Math.floor(this.props.documentWidth / this.COLS);
    this.ROWS = Math.ceil(this.props.documentHeight / this.SPACING) + 2;
    this.NUM_PARTICLES = ((this.ROWS) * (this.COLS));
    this.MARGIN = this.SPACING;
    this.ctx = this.canvas.getContext('2d');
    this.man = true;
    this.tog = false;

    this.list = [];

    this.w = this.canvas.width = this.props.documentWidth;
    this.h = this.canvas.height = this.props.documentHeight;

    for (let i = 0; i < this.NUM_PARTICLES; i++) {

      this.p = Object.create(this.particle);
      this.p.x = this.p.ox = this.MARGIN + this.SPACING * (i % this.COLS);
      this.p.y = this.p.oy = this.MARGIN + this.SPACING * Math.floor(i / this.COLS);

      this.list[i] = this.p;
    }
  }
 
  step() {    
   
    this.tog = !this.tog;
    if (this.tog) {

      if (!this.man) {

        this.t = +new Date() * 0.001;
        this.mx = this.w * 0.5 + (Math.cos(this.t * 2.1) * Math.cos(this.t * 0.9) * this.w * 0.45);
        this.my = this.h * 0.5 + (Math.sin(this.t * 3.2) * Math.tan(Math.sin(this.t * 0.8)) * this.h * 0.45);
      }

      for (let i = 0; i < this.NUM_PARTICLES; i++) {

        this.p = this.list[i];

        this.d = (this.dx = this.mx - this.p.x) * this.dx + (this.dy = this.my - this.p.y) * this.dy;
        this.f = -this.THICKNESS / this.d;

        if (this.d < this.THICKNESS) {
          this.t = Math.atan2(this.dy, this.dx);
          this.p.vx += this.f * Math.cos(this.t);
          this.p.vy += this.f * Math.sin(this.t);
        }

        this.p.x += (this.p.vx *= this.DRAG) + (this.p.ox - this.p.x) * this.EASE;
        this.p.y += (this.p.vy *= this.DRAG) + (this.p.oy - this.p.y) * this.EASE;

      }

    } else {

      this.b = (this.a = this.ctx.createImageData(this.w, this.h)).data;

      for (let i = 0; i < this.NUM_PARTICLES; i++) {

        this.p = this.list[i];
        this.b[this.n = (~~this.p.x + (~~this.p.y * this.w)) * 4] = this.b[this.n + 1] = this.b[this.n + 2] = this.COLOR;
        this.b[this.n + 3] = 255;
      }

      this.ctx.putImageData(this.a, 0, 0);
    }
    requestAnimationFrame(this.step);
  }
  
  render() {
    return (<canvas className={`main-grid ${this.props.menuOpened ? 'main-grid--blurred':''}`} ref="canvas"></canvas>)
  }
}

export default Grid;