import React, {useState, useRef, useEffect, useContext} from 'react';

import { Link } from 'react-router-dom';
import './index.scss';
import { DocumentContext } from '../../contexts/DocumentContext';


export default function ProjectThumbnail(props) {
  const element = useRef();
  const [rect, setRect] = useState({});
  const [started, setStarted] = useState(false);
  const [position, setPosition] = useState({
    top: 0,
    left: 0,
  });
  const {documentData, setDocumentData} = useContext(DocumentContext);
  useEffect(() => {
    if(!started) {
      setStarted(true);
      setRect(element.current.getBoundingClientRect());
    }
    if(documentData.animated) {
      setPosition({
        top: 'top' in rect ? props.mouseY - rect.top + document.querySelectorAll('.content-wrapper')[0].scrollTop : 0,
        left: 'left' in rect ? props.mouseX - rect.left : 0,
      })
    }
    
  }, [props.mouseY, props.mouseX])
  function renderLanguages(project) {
    return project.data.languages.map((element, index) => {
      let html = index ? ' / ':'';
      html += element.language;
      return html;
    }).join('');
  }
  return (
      <Link to={props.href} className={`project-thumbnail transition-aside-element`} key={props.project.id} onMouseEnter={props.onMouseEnter} onMouseLeave={props.onMouseLeave} ref={element}>
        {
          documentData.animated &&
          <span className="project-thumbnail__background" style={{...position}}></span>

        }
        <span className="project-thumbnail__title" dangerouslySetInnerHTML={{__html:props.project.data.title[0].text}} ></span>
        <span className="project-thumbnail__techno" dangerouslySetInnerHTML={{ __html: renderLanguages(props.project) }}></span>
      </Link>
  );
}