import React from 'react';
import { Curtains } from 'curtainsjs';
import './index.scss';
import displacement from '../../assets/images/displacement.jpg';

let transitionTimer = 0;
let activeTex = null;
let nextTex = null;

class Previewer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      plane: null,
      isChanging: false,
      webGLCurtain: null,
      activeTexture: null,
      nextTexture: null,
      activeTextureIndex: 1,
      nextTextureIndex: 2,
      transitionTimer: 0,
      hidden: false,
      params: {},
    };
    this.planeElement = React.createRef();
  }
  componentDidMount() {
    this.setState({
      ...this.state,
      webGLCurtain: new Curtains('previewer-canvas'),
      activeTexture: 1,
      transitionTimer: 0,
      maxTextures: this.props.data.length-1,
      params: {
        vertexShaderID: "multiple-textures-vs", 
        fragmentShaderID: "multiple-textures-fs",
        widthSegments: 20,
        heightSegments: 10,
        imageCover: false,
        uniforms: {
          time: {
            name: "uTime",
            type: "1f",
            value: 0,
          },
          transitionTimer: {
            name: "uTransitionTimer",
            type: "1f",
            value: 0,
          },
        }
      }
    }, () => {
      this.state.webGLCurtain.disableDrawing();
      this.setState({
        ...this.state,
        plane: this.state.webGLCurtain.addPlane(this.planeElement, this.state.params)
      }, () => {
        this.setState({
          ...this.state,
          activeTexture: this.state.plane.createTexture("activeTexture"),
          nextTexture: this.state.plane.createTexture("nextTexture"),
        }, () => {
          this.handlePlane();
        });
        
      });
    });
   
  }
  shouldPreviewerHide(pathname) {
    const test = /^(\/references\/[^/]*?)$/gm;
    const result = test.exec(pathname);
    let shouldHide = false;
    if(result && result.length){
      shouldHide = true;
    }
    return shouldHide;
  }
  changeTexture() {
    let nextTextureIndex;
    if(this.state.activeTextureIndex === this.state.maxTextures + 1) {
      nextTextureIndex = 1;
    } else {
      nextTextureIndex = this.state.activeTextureIndex + 1;
    }

    this.setState({
      ...this.state,
      nextTextureIndex: nextTextureIndex,
      isChanging: true,
    }, () => {
      nextTex.setSource(this.state.plane.images[this.state.nextTextureIndex]);
      this.setState({
        ...this.state,
        activeTextureIndex: this.state.nextTextureIndex, 
      }, () => {
        setTimeout(() => {
          this.setState({
            ...this.state,
            isChanging: false,
          }, () => {
            activeTex.setSource(this.state.plane.images[this.state.activeTextureIndex]);
            transitionTimer = 0;
          });          
        }, 1600);
      });   
    });

    
    
        
  }
  renderPreviews() {
    return this.props.data.map((preview) => {
      const jsx = preview.slider_img.url ? <img src={preview.slider_img.url} alt="" key={preview.slider_img.url} crossOrigin="anonymous"/>:null;
      return jsx;
    });
  }
  handlePlane() {
    activeTex = this.state.plane.createTexture("activeTex");
    nextTex = this.state.plane.createTexture("nextTex");
    this.state.plane && this.state.plane.onReady(() => {
      this.state.webGLCurtain.needRender();
      
      // we set our very first image as the active texture
      activeTex.setSource(this.state.plane.images[this.state.activeTextureIndex]);
      nextTex.setSource(this.state.plane.images[this.state.nextTextureIndex]);
      this.state.webGLCurtain.enableDrawing();
      setInterval(() => {
        this.changeTexture();
      }, 6000);
    }).onRender(() => {          
        if(this.state.isChanging) {
          transitionTimer = Math.min(60, transitionTimer + 1);
        }          
        this.state.plane.uniforms.transitionTimer.value = transitionTimer;
        this.state.plane.uniforms.time.value++;
      });
  }
  registerPlaneElement(element) {
    this.planeElement = element;
  }
  render() {
    if (this.state.webGLCurtain) {
      if(this.shouldPreviewerHide(this.props.currentRoute)){
        this.state.webGLCurtain.disableDrawing();
      }
      else {
        this.state.webGLCurtain.enableDrawing();
      }
    }
    return (
      <React.Fragment>
        <div id="previewer-canvas"
        
        
              className={`${this.props.className} ${this.shouldPreviewerHide(this.props.currentRoute) ? 'hidden':''}`}
           ></div>
        <div 
          className={`previewer previewer--${this.props.type}`}
          ref={(element) => this.registerPlaneElement(element)}
          >
            <img src={displacement} alt="" data-sampler="displacement" crossOrigin="anonymous" />
            {this.renderPreviews()}
        </div>
        {this.props.children}
      </React.Fragment>
    )
  }
}

export default Previewer;