import React from 'react';
import SVG from 'react-inlinesvg';
import Helmet from 'react-helmet';

import Title from './Title';
import Page from '../../components/Page';
import Button from '../../components/Button';

import './index.scss';

function NotFound(props) {
    return (
      <Page { ...props }>
        <Helmet>
          <title>Développement 🐱‍💻 de sites web  et d'applications mobiles : erreur 404</title>
          <meta name="description" content="Portfolio d'Enguerran Weiss, designer et développeur web indépendant à Poitiers (86) - HTML, CSS, JS, React, PHP, NodeJS, React Native, Wordpress, Symfony" />
          <meta property="og:type" content="website" />
          <meta property="og:url" content="https://metatags.io/" />
          <meta property="og:title" content="Design et développement web : Enguerran Weiss, développeur web indépendant"/>
          <meta property="og:description" content="Portfolio d'Enguerran Weiss, designer et développeur web indépendant à Poitiers (86) - HTML, CSS, JS, React, PHP, NodeJS, React Native, Wordpress, Symfony"/>
          <meta property="og:image" content=""/>
          <meta property="twitter:card" content="summary_large_image"/>
          <meta property="twitter:url" content="https://metatags.io/"/>
          <meta property="twitter:title" content="Design et développement web : Enguerran Weiss, développeur web indépendant"/>
          <meta property="twitter:description" content="Portfolio d'Enguerran Weiss, designer et développeur web indépendant à Poitiers (86) - HTML, CSS, JS, React, PHP, NodeJS, React Native, Wordpress, Symfony"/>
          <meta property="twitter:image" content=""/>
        </Helmet>
        <Title color={props.color}/>
        <div className="buttons-container transition-aside-element">
          <Button onMouseLeave={props.onMouseLeave} onMouseEnter={props.onMouseEnter} href="/references" icon={<SVG src="/images/icons/dice-d10.svg" />} label="voir mes références" altLabel='ポートフォリオを見る'/>
          <Button onMouseLeave={props.onMouseLeave} onMouseEnter={props.onMouseEnter} out href="https://github.com/enguerranws" icon={<SVG src="/images/icons/github.svg" />} />
          <Button onMouseLeave={props.onMouseLeave} onMouseEnter={props.onMouseEnter} out href="https://www.linkedin.com/in/enguerran-weiss/" icon={<SVG src="/images/icons/linkedin-in.svg" />} />
        </div>      
      </Page>
      
    )


}
export default NotFound;