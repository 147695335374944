import React, {useContext} from 'react';
import './index.scss';
import { DocumentContext } from '../../contexts/DocumentContext';


export default function BlockWrapper(props) {
  const {documentData} = useContext(DocumentContext);
  const unit = (documentData.width / documentData.gridColumns);

  return (
    <div className={`block-wrapper`} style={{gridGap: `${unit}px` }}>
      {props.children}
    </div>
  )
}