import React from 'react';
import ReactDOM from 'react-dom';
import './global/reset.scss';
import './global/colors.scss';
import './global/layout.scss';
import './global/fonts.scss';
import './global/japan.scss';
import App from './App';
import * as serviceWorker from './serviceWorker';

ReactDOM.render([<App />], document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
