import React, {useState, useEffect} from 'react';
import { Link } from "react-router-dom";
import './index.scss';

function Logo(props) {
  const [logoVisible, setLogoVisible] = useState(false);
  useEffect(() => {
    setTimeout(() => {
      setLogoVisible(true)
    }, 2000);
  }, [])
  function onLogoClick(event) {
    document.activeElement.blur()
  }
  return (
    <Link className={`main-logo ${logoVisible ? 'main-logo--visible':''}`} tabIndex="0" to="/" onMouseEnter={props.onMouseEnter} aria-label="Retour à la page d'accueil" onMouseLeave={props.onMouseLeave} onClick={(event) => onLogoClick(event)}>
      <h1>
        <svg aria-labelledby="main-logo-title" width="50" height="49" xmlns="http://www.w3.org/2000/svg">
          <title id="main-logo-title">Enguerran Weiss : développeur web indépendant - retour à l'accueil</title>
          <g fill="#FFF" fillRule="nonzero">
            <path d="M36.146527 49H13.853473L0 31.366705 4.942433 9.681584 24.990298 0l20.069425 9.54462L50 31.392387 36.146527 49zm-20.075894-4.280099h17.860171l11.168447-14.307658-3.988012-17.691075-16.10513-7.687058-16.11591 7.767666-3.986575 17.59834 11.167009 14.319785z"/>
            <path d="M22.664689 21h3.672033l1.59439 8.995115h.04374L29.066938 21H33l-2.665311 13h-4.457235l-1.355231-8.738365h-.04374L23.123957 34h-4.45794L16 21h3.933062l1.223306 8.995115h.04374z"/>
          </g>
        </svg>
      </h1>
      


    </Link>
    
  )

}

export default Logo;