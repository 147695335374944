import React from 'react';

class Title extends React.PureComponent {
  componentDidMount() {

  }
  render() {
    return (
      <div className="main-title">
        
        <h1><span className="transition-inline-element">Me</span> <strong className="transition-inline-element">contacter</strong></h1>
        <small className="label-jp transition-inline-element--jp" lang="ja">メッセージ</small> 
      </div>
    )
  }

}

export default Title;