import React, { useLayoutEffect, useContext, useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { Transition, TransitionGroup } from 'react-transition-group';
import Logo from '../Logo';
import Grid from '../Grid';
import Footer from '../Footer';
import Background from '../Background';
import AvoidMenu from '../AvoidMenu';

import Menu from '../Menu';
import Project from '../../templates/Project';
import Home from '../../templates/Home';
import Projects from '../../templates/Projects';
import NotFound from '../../templates/NotFound';
import { DocumentContext } from '../../contexts/DocumentContext';
import { play } from '../../timelines'; 
import Contact from '../../templates/Contact';
import About from '../../templates/About';
import Legals from '../../templates/Legals';

export default function Layout(props) {
  const {documentData, setDocumentData} = useContext(DocumentContext);
  const [currentRoute, setCurrentRoute] = useState('');
  const [mouseX, setMouseX] = useState(0);
  const [mouseY, setMouseY] = useState(0);
  const [mouseStopped, setMouseStopped] = useState(true);
  const [aboutData, setAboutData] = useState({});
  let mouseTimeout = setTimeout( () => {    
    setMouseStopped(true);
  }, 200);
  const originalMouseTimeout = mouseTimeout;
  let appWrapper = React.createRef();
  useEffect(() => {
    let url = '/tools.php';
    let data = new FormData();
    data.append('action', 'getAboutData');
    if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
      url = 'http://localhost:8888/ew-2019/public/tools.php';
    }    
    fetch(url, {method: 'POST', mode: 'no-cors', body: data}).then((response) => response.json()).then(json => {
      setAboutData(json);
    });
  }, [])
  useLayoutEffect(() => {
    function updateSize() {      
      setDocumentData({
        ...documentData,
        width: window.innerWidth,
        height: window.innerHeight,
        columns: window.innerWidth < 1000 ? 24:48,
      });
    }
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, [documentData.animated]);
  function updateCurrentRoute(pathname) {
    setCurrentRoute(pathname);
  }
  function onMouseMove(e) {
    let mouseStopped = false;
    if (mouseTimeout !== undefined) {
      window.clearTimeout(mouseTimeout);
      mouseStopped = false;
    }
    setMouseX(e.clientX);
    setMouseY(e.clientY);
    setMouseStopped(mouseStopped);
    mouseTimeout = originalMouseTimeout;
  }
  function onMouseEnter() {
  }
  function onMouseLeave() {
  }
  return (
    <div className={`app-wrapper app-wrapper--ready`} ref={appWrapper} onMouseMove={
      (e) => onMouseMove(e)
    }>
      <AvoidMenu />
      <Background menuOpened={documentData.menuOpened} />
      {
        documentData.animated &&
        <Grid menuOpened={documentData.menuOpened} mouseEnd={mouseStopped} mouseX={documentData.menuOpened ? -30:mouseX} mouseY={documentData.menuOpened ? -30:mouseY} columns={documentData.columns} documentWidth={documentData.width+300} documentHeight={documentData.height+300}/>

      }
      <Router>
        <div className="content-wrapper">
          <header className="main-header">                          
            <Menu />
          </header> 
          <main className={`main-wrapper ${documentData.menuOpened ? 'menu-opened': ''}`}>
          <Logo />  
          <Route render={({ location }) => {
              const { pathname, key } = location
              return (
                <TransitionGroup component={null}>
                  <Transition
                    key={key}
                    appear={true}
                    onEnter={documentData.animated ? (node, appears) => play(pathname, node, appears) : () => {}}
                    timeout={{enter: 100, exit: 100}}
                  >
                    <Switch location={location}>
                      <Route exact path="/" children={({ match, ...rest }) =>   {
                       
                        return (
                          <React.Fragment>
                          {match && <Home {...rest} onMouseEnter={() => onMouseEnter()} onMouseLeave={() => onMouseLeave()} documentWidth={documentData.width} columns={documentData.gridColumns} documentHeight={documentData.height} updateCurrentRoute={(pathname) => updateCurrentRoute(pathname)} />}
                        </React.Fragment> 
                        )
                      }

                           
                        }/>

                  <Route 
                    path="/projets-developpement-web-mobile" 
                    exact
                    children={({ match, ...rest }) => (
                      <React.Fragment>
                        {match && <Projects className="projects" {...rest} mouseX={mouseX} mouseY={mouseY} onMouseEnter={() => onMouseEnter()} onMouseLeave={() => onMouseLeave()} updateCurrentRoute={(pathname) => updateCurrentRoute(pathname)} data={props.projects} documentWidth={documentData.width} columns={documentData.gridColumns} documentHeight={documentData.height} />}
                      </React.Fragment>)
                    }
                  />
                    <Route 
                      path={`/projets-developpement-web-mobile/:projectUid`}
                      exact 
                      children={({ match, ...rest }) => (
                        <React.Fragment>
                          {match && <Project className="project-single" match={match} {...rest} mouseX={mouseX} mouseY={mouseY} onMouseEnter={() => onMouseEnter()} onMouseLeave={() => onMouseLeave()} data={props.projects} updateCurrentRoute={(pathname) => updateCurrentRoute(pathname)} documentWidth={documentData.width} columns={documentData.gridColumns} documentHeight={documentData.height}/>}
                        </React.Fragment>)
                      }
                      />
                      <Route 
                      path={`/contact`}
                      exact 
                      children={({ match, ...rest }) => (
                        <React.Fragment>
                          
                          {match && <Contact data={aboutData} className="contact" match={match} {...rest} mouseX={mouseX} mouseY={mouseY} onMouseEnter={() => onMouseEnter()} onMouseLeave={() => onMouseLeave()} updateCurrentRoute={(pathname) => updateCurrentRoute(pathname)} documentWidth={documentData.width} columns={documentData.gridColumns} documentHeight={documentData.height}/>}
                        </React.Fragment>)
                      }
                      />
                      <Route 
                      path={`/a-propos`}
                      exact 
                      children={({ match, ...rest }) => (
                        <React.Fragment>
                          
                          {match && <About className="about" data={aboutData} match={match} {...rest} mouseX={mouseX} mouseY={mouseY} onMouseEnter={() => onMouseEnter()} onMouseLeave={() => onMouseLeave()} updateCurrentRoute={(pathname) => updateCurrentRoute(pathname)} documentWidth={documentData.width} columns={documentData.gridColumns} documentHeight={documentData.height}/>}
                        </React.Fragment>)
                      }
                      />
                      <Route 
                      path={`/mentions-legales`}
                      exact 
                      children={({ match, ...rest }) => (
                        <React.Fragment>
                          
                          {match && <Legals className="legals" match={match} {...rest} mouseX={mouseX} mouseY={mouseY} onMouseEnter={() => onMouseEnter()} onMouseLeave={() => onMouseLeave()} data={props.projects} updateCurrentRoute={(pathname) => updateCurrentRoute(pathname)} documentWidth={documentData.width} columns={documentData.gridColumns} documentHeight={documentData.height}/>}
                        </React.Fragment>)
                      }
                      />
                      <Route 
                      children={({ match, ...rest }) => (
                        <React.Fragment>
                          
                          {match && <NotFound className="legals" match={match} {...rest} mouseX={mouseX} mouseY={mouseY} onMouseEnter={() => onMouseEnter()} onMouseLeave={() => onMouseLeave()} data={props.projects} updateCurrentRoute={(pathname) => updateCurrentRoute(pathname)} documentWidth={documentData.width} columns={documentData.gridColumns} documentHeight={documentData.height}/>}
                        </React.Fragment>)
                      }
                      />
                    </Switch>
                  </Transition>
                </TransitionGroup>
              )
            }}/>
          </main>
        </div>
        <Footer documentWidth={documentData.width} columns={documentData.gridColumns} />

      </Router>
  </div>
  )
}