import React from 'react';

class Title extends React.PureComponent {
  componentDidMount() {

  }
  render() {
    return (
      <div className="main-title main-title--home">
        
        <h2><span className="transition-letter-element">j</span><span className="transition-letter-element">e</span> <span className="transition-letter-element">s</span><span className="transition-letter-element">u</span><span className="transition-letter-element">i</span><span className="transition-letter-element">s</span><span className="transition-letter-element">&nbsp;</span><br /><strong className="transition-letter-element">d</strong><strong className="transition-letter-element">e</strong><strong className="transition-letter-element">s</strong><strong className="transition-letter-element">i</strong><strong className="transition-letter-element">g</strong><strong className="transition-letter-element">n</strong><strong className="transition-letter-element">e</strong><strong className="transition-letter-element">r</strong><span className="transition-letter-element">&nbsp;</span><span className="transition-letter-element">&amp;</span><br /><strong className="transition-letter-element">d</strong><strong className="transition-letter-element">é</strong><strong className="transition-letter-element">v</strong><strong className="transition-letter-element">e</strong><strong className="transition-letter-element">l</strong><strong className="transition-letter-element">o</strong><strong className="transition-letter-element">p</strong><strong className="transition-letter-element">p</strong><strong className="transition-letter-element">e</strong><strong className="transition-letter-element">u</strong><strong className="transition-letter-element">r</strong><span className="transition-letter-element">&nbsp;</span><span className="transition-letter-element">w</span><span className="transition-letter-element">e</span><span className="transition-letter-element">b</span></h2>
        <small className="label-jp transition-inline-element--jp" lang="ja">私は、ウェブ<strong>デザイナー</strong>とウェブ<strong>デベロッパー</strong>です。</small> 
      </div>
    )
  }

}

export default Title;