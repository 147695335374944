import React from 'react';

class Title extends React.PureComponent {
  componentDidMount() {

  }
  render() {
    return (
      <div className="main-title main-title--home">
        
        <h1 className="transition-inline-element"><strong>Erreur</strong> 404 😔</h1>
        <small>Cette page n'existe pas !</small>
      </div>
    )
  }

}

export default Title;