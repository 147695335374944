import { gsap } from 'gsap';
import { CSSPlugin } from 'gsap/CSSPlugin';
import { TimelineMax as Timeline, Power1 } from 'gsap';

// Force CSSPlugin to not get dropped during build
gsap.registerPlugin(CSSPlugin);

const getDefaultTimeline = (node, delay) => {
  const timeline = new Timeline({ paused: true });
  const letters = node.querySelectorAll('.transition-letter-element');
  const texts = node.querySelectorAll('.transition-inline-element');
  const japanTexts = node.querySelectorAll('.transition-inline-element--jp');
  const asides = node.querySelectorAll('.transition-aside-element');
  if(letters.length) {
    timeline
    .from(node, 0, { display: 'none', autoAlpha: 0, delay })
    .staggerFrom(letters, 0.3, { autoAlpha: 0, y: -10, ease: Power1.easeOut }, 0.06);
  }
  if(texts.length) {
    timeline
      .from(node, 0, { display: 'none', autoAlpha: 0, delay })
      .staggerFrom(texts, 0.3, { autoAlpha: 0, x: -50, ease: Power1.easeOut }, 0.15);
  }
  if(asides.length) {
    timeline
      .from(node, 0, { display: 'none', autoAlpha: 0, delay })
      .staggerFrom(asides, 0.3, { autoAlpha: 0, ease: Power1.easeOut }, 0.2);
  }
  if(japanTexts.length) {
    timeline
      .from(node, 0, { display: 'none', autoAlpha: 0, delay })
      .staggerFrom(japanTexts, 0.4, { autoAlpha: 0, y: -10, ease: Power1.easeOut }, 0.1);
  }
  

  return timeline;
}

export const play = (pathname, node, appears) => {
  const delay = appears ? 0 : .4;
  const timeline = getDefaultTimeline(node, delay);

  requestAnimationFrame(() => timeline.play());
}

