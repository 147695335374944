import React, {useState} from 'react';
import './index.scss';

function Accordion(props) {  
  const [opened, setOpened] = useState(false);

  return (
    <div className={'accordion'}>
      <h3 className={'accordion__title'} tabIndex="1" onClick={() => setOpened(!opened)}>{props.title} <span className={`accordion__icon ${opened ? 'opened':''}`}></span></h3>
      <div className={`accordion__content ${opened ? 'visible':''}`}>
        { props.children }
      </div>      
    </div>
  )
    
}

export default Accordion;