import React, { useEffect, useState } from 'react';
import SVG from 'react-inlinesvg';
import Emoji from 'a11y-react-emoji';
import Helmet from 'react-helmet';

import Button from '../../components/Button'; 
import Page from '../../components/Page';
import BlockWrapper from '../../components/BlockWrapper';
import Block from '../../components/Block';
import './index.scss';
import Accordion from '../../components/Accordion';

import opquast from '../../assets/images/logo-opquast.png';
import mwe from '../../assets/images/logo-mwe.png';

function About (props) {
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(true);
  useEffect(() => {
   setData(props.data);
  }, [props.data])
  return (
      <Page {...props}>
        <Helmet>
          <title>Développeur web basé à Poitiers (86) : Enguerran Weiss, CV et parcours</title>
          <meta name="description" content="Page de présentation d'Enguerran Weiss, développeur web indépendant : expérience, formation, diplômes, publications." />
          <meta property="og:type" content="website" />
          <meta property="og:url" content="https://metatags.io/" />
          <meta property="og:title" content="Présentation d'Enguerran Weiss, développeur web indépendant : expérience, formation, diplômes, publications."/>
          <meta property="og:description" content="Page de présentation d'Enguerran Weiss, développeur web indépendant : expérience, formation, diplômes, publications."/>
          <meta property="og:image" content=""/>
          <meta property="twitter:card" content="summary_large_image"/>
          <meta property="twitter:url" content="https://metatags.io/"/>
          <meta property="twitter:title" content="Présentation d'Enguerran Weiss, développeur web indépendant : expérience, formation, diplômes, publications."/>
          <meta property="twitter:description" content="Portfolio d'Enguerran Weiss, designer et développeur web indépendant à Poitiers (86) - HTML, CSS, JS, React, PHP, NodeJS, React Native, Wordpress, Symfony"/>
          <meta property="twitter:image" content=""/>
        </Helmet>
          <header className="page__header transition-aside-element">
            <h2>Enguerran <strong>Weiss</strong></h2>
            <h3 className='label-jp' lang="ja">私について、 アンゲラン　ヴァイス。</h3>
            <div className={'buttons-container'}>
              <Button onMouseLeave={props.onMouseLeave} ariaLabel="Voir mon profil Github" small onMouseEnter={props.onMouseEnter} out href="https://github.com/enguerranws" icon={<SVG src="/images/icons/github.svg" />} />
              <Button onMouseLeave={props.onMouseLeave} ariaLabel="Voir ma page Linkedin" small onMouseEnter={props.onMouseEnter} out href="https://www.linkedin.com/in/enguerran-weiss/" icon={<SVG src="/images/icons/linkedin-in.svg" />} />
              <Button onMouseLeave={props.onMouseLeave} ariaLabel="Voir mon profil Twitter" small onMouseEnter={props.onMouseEnter} out href="https://twitter.com/enguerranws" icon={<SVG src="/images/icons/twitter.svg" />} />
            </div>
          </header>
          <section className="transition-aside-element page__content ">
            <div className={'page__resume'}>
              <p>Je ne suis pas un <strong>JS Guru</strong>, un <strong>UX Ninja</strong>, ou autre, juste un <strong>designer et développeur web / mobile <Emoji symbol="👨‍💻" label="Homme travaillant derrière un ordinateur" /></strong> freelance avec un gros penchant pour les sites et applications adaptatives. Je donne aussi des <strong>cours et interventions à l'Université de Poitiers</strong> <Emoji symbol="👨‍🏫" label="Enseignant" /> (Master Web édito, Master IME et esDoc). Et quand j'ai fini de travailler, <strong>j'organise des trucs pour <a target="_blank" rel="noopener noreferrer" href="https://pwn-association.org/">PWN</a></strong> et je joue de la musique.</p>
            </div>
           
            <aside className="page__aside">
          <BlockWrapper>
            <Block className="block--dashed" href="https://stackoverflow.com/users/2054977/enguerranws?tab=profile" out>
              {
                !!data.so_reputation &&
                <div className="block__data-inner">
                  <span className="block__data-value">
                    {data.so_reputation}
                  </span>
                  Réputation / StackOverflow
                </div>
              }
              
            </Block>
            
            
            {
                !!data.gh_actions &&
                <Block className="block--dashed" href="https://github.com/enguerranws/" out>
                  <div className="block__data-inner">
                    <span className="block__data-value">
                      {data.gh_actions}
                    </span>
                    Interactions sur Github depuis le début du mois
                  </div>
                </Block>
              }
            
            
            <Block className="block--dashed">
              {
                !!data.projects_number &&
                <div className="block__data-inner">
                  <span className="block__data-value">
                    {data.projects_number}
                  </span>
                  Projets mis en ligne
                </div>
              }
            </Block>
            <Block className="block--dashed" href="https://slides.com/enguerranweiss/" out>
              {
                !!data.slides_number &&
                <div className="block__data-inner">
                  <span className="block__data-value">
                    {data.slides_number}
                  </span>
                  Présentations préparées
                </div>
              }
            </Block>
            <Block className="block--dashed" href="https://directory.opquast.com/fr/certificat/OJAE4U/" out>

                <div className="block__data-inner">
                  <span className="block__data-value">
                    <img src={opquast} alt="Opquast"/>
                  </span>
                  Certification niveau Expert
                </div>
            </Block>
            <Block className="block--dashed" href="http://sha.univ-poitiers.fr/masterweb/" out>
              <div className="block__data-inner">
                <span className="block__data-value">
                  <img className="full" src={mwe} alt="Master Web éditorial"/>
                </span>
              </div>
            </Block>
        </BlockWrapper>
          </aside>
        <Accordion title={'Technologies'}>
          <ul>
            <li><strong><Emoji symbol="🖥️" label="ordinateur" /> Dév. front</strong> : HTML, CSS, React, Angular, VueJS, jQuery, Vanilla JS et un paquet de librairies (ThreeJS, D3, Snap.svg, Raphael, GSAP, etc.)</li>
            <li><strong><Emoji symbol="🤖" label="robot" /> Dév. back</strong> : NodeJS, Express, Parse, PHP, Wordpress, Symfony</li>
            <li><strong><Emoji symbol="📱" label="mobile" /> Dév. mobile</strong> : React Native, Cordova, Ionic</li>
          </ul>
        </Accordion>
        <Accordion title={'Expérience'}>
          <ul>
            <li className="current"><Emoji symbol="✅" label="coche verte" /> <strong>Designer &amp; développeur web indépendant</strong> <span> à Poitiers (86) depuis oct. 2012</span></li>
            <li className="current"><Emoji symbol="✅" label="coche verte" /> <strong>Intervenant en Master Info-com</strong> (<a href="http://sha.univ-poitiers.fr/masterweb/" target="_blank" rel="noopener noreferrer">Master Web Éditorial</a>, <a href="http://sha.univ-poitiers.fr/masteresdoc" target="_blank" rel="noopener noreferrer">Master esDoc</a>)<span>à l'Université de Poitiers (86) depuis sept. 2013</span></li>
            <li><strong>Designer &amp; développeur web/strong</strong><span> chez 37e Parallèle, Rouen (76) de sept. 2012 à nov. 2012</span></li>
            <li><strong>D.A. junior</strong><span> chez Milky, Nanterre (92) de janv. 2012 à sept. 2012</span></li>
            <li><strong>D.A. junior</strong><span> chez Milky Interactive, Paris (75) de sept. 2011 à janv. 2012</span></li>
          </ul>
        </Accordion>
        <Accordion title={'Diplômes / certifications'}>
          <ul>
            <li><strong>Master web éditorial</strong> - <span>Université de Poitiers (86), mention TB. Mémoire : “RESPONSIVE WEB DESIGN : Enjeux, observations et préconisations”.</span></li>
            <li><strong>Expert en Qualité web</strong> - <span>Opquast</span></li>
            <li><strong>Maîtrise d'Histoire de l'art</strong> - <span>Université de Poitiers (86), spécialité en art contemporain, mention B. </span></li>

          </ul>
        </Accordion>
        <Accordion title={'Publications'}>
          <ul>
            <li><a target="_blank" rel="noopener noreferrer" href="http://uptv.univ-poitiers.fr/program/campus-europeen-d-ete-2013etnbsp-ux-designetnbsp-l-experience-utilisateur-au-service-des-apprentissagesetnbsp/video/3898/le-responsive-web-design-phenomene-de-mode-ou-methode-de-conception-durable/index.html">Le Responsive Web Design&nbsp;: phénomène de mode ou méthode de conception durable&nbsp;?</a> <span>Conférence pour le Campus Européen d'été (UX design : l'expérience utilisateur au service des apprentissages ?), sept.2013</span></li>
            <li><a target="_blank" rel="noopener noreferrer" href="http://www.oracomeditions.fr/?p=889">Savoir tout faire pour le web : Responsive Design </a><span>p.15-59, Oracom Editions</span></li>
            <li><a target="_blank" rel="noopener noreferrer" href="http://shop.oracom.fr/num%C3%A9ros-sp%C3%A9ciaux/538-1.html">Maîtriser le Responsive Design</a> <span>(p.15-44), Web design HS19,Oracom</span></li>
            <li><a target="_blank" rel="noopener noreferrer" href="http://enguerranweiss.fr/memoire/e-weiss_memoire-v09.pdf">Responsive Web Design : Enjeux, observations et préconisations</a> <span>mémoire de Master Web éditorial, Université de Poitiers, 2012, mention TB</span></li>

          </ul>
        </Accordion>
        {/* <Accordion title={'Conférences & talks'}>
          <ul>
            <li><a target="_blank" rel="noopener noreferrer" href="http://uptv.univ-poitiers.fr/program/campus-europeen-d-ete-2013etnbsp-ux-designetnbsp-l-experience-utilisateur-au-service-des-apprentissagesetnbsp/video/3898/le-responsive-web-design-phenomene-de-mode-ou-methode-de-conception-durable/index.html">Le Responsive Web Design&nbsp;: phénomène de mode ou méthode de conception durable&nbsp;?</a> <span>Conférence pour le Campus Européen d'été (UX design : l'expérience utilisateur au service des apprentissages ?), sept.2013</span></li>
            <li><a target="_blank" rel="noopener noreferrer" href="http://www.oracomeditions.fr/?p=889">Savoir tout faire pour le web : Responsive Design </a><span>p.15-59, Oracom Editions</span></li>
            <li><a target="_blank" rel="noopener noreferrer" href="http://shop.oracom.fr/num%C3%A9ros-sp%C3%A9ciaux/538-1.html">Maîtriser le Responsive Design</a> <span>(p.15-44), Web design HS19,Oracom</span></li>
            <li><a target="_blank" rel="noopener noreferrer" href="http://enguerranweiss.fr/memoire/e-weiss_memoire-v09.pdf">Responsive Web Design : Enjeux, observations et préconisations</a> <span>mémoire de Master Web éditorial, Université de Poitiers, 2012, mention TB</span></li>

          </ul>
        </Accordion>     */}
         
          </section>
          {/* <aside className="project-single__content project-single__main-img">
            <img src={project.data.main_img.url} alt={project.data.title[0].text} />
          </aside> */}
         
          {/* <div className="page__content page__buttons buttons-container  transition-aside-element">
            <Button onMouseLeave={props.onMouseLeave} onMouseEnter={props.onMouseEnter} href="/references" icon={<SVG src="/images/icons/cubes.svg" />} label="retour à la liste" />
          </div> */}
         
  </Page>
  
  )
}
export default About;