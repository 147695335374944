import React, {useContext} from 'react';
import { DocumentContext } from '../../contexts/DocumentContext';
import './index.scss';

function Background(props) {    
  const {documentData} = useContext(DocumentContext);

    return (
      <div className={`main-background ${props.menuOpened || !documentData.animated ? 'main-background--stopped':''}`}></div>
    )
}

export default Background;